/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import ReactPlayer from 'react-player/lazy'


// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/elements/Hero'
import ParseContent from 'components/shared/ParseContent'
import AboutUs from 'components/shared/before-footer/AboutUs'
import Vacancies from 'components/Vacancies'

const StyledPlayer = styled(ReactPlayer)`
  position: relative;
  background-color: #000000;
  height: 100%;
  width: 100%;
`

const PlayerContainer = styled.div`
  width: 100%;
  height: 306px;
  position: relative;

  @media screen and (max-width: 991px) {
    width: 100%;
    min-height: 250px;
    right: 0;
  }
`

export const pageQuery = graphql`
  query($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      uri
      pageVacancys {
        fieldGroupName
        vacaturepagebanner {
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality:100)
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        vacaturepageintro {
           title
           description
        }

        title
        videos {
          url
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { seo, pageVacancys },
  },
}) => {
  return (
    <Layout withButton={false}>
      {seo && (
        <SEO
          seo={seo}
          socialImage={pageVacancys.vacaturepagebanner.image.localFile.childImageSharp.meta.src}
        />
      )}

      <Hero src={pageVacancys.vacaturepagebanner.image} title={pageVacancys.vacaturepagebanner.title} />

      <section className="mb-5">
        <div className="container pb-5">
          <div className="row py-5">
            <div className="col-lg-4 ml-lg-n5">
              <ParseContent content={pageVacancys.vacaturepageintro.title} />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7 pl-lg-5 pr-5">
              <ParseContent content={pageVacancys.vacaturepageintro.description} />
            </div>
            <div className="col-lg-5 pl-lg-5 pb-lg-5 mb-lg-5">
              <Vacancies />
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-12 mb-3 ">
              {pageVacancys.title && (
                <ParseContent content={pageVacancys.title}  />
              )}
            </div>
              {pageVacancys.videos && (
                pageVacancys.videos.map(({ url }, index) => (
                  <div className="col-md-6">
                  <PlayerContainer className="mb-5">
                    <StyledPlayer width="100%" height="100%" controls url={url} />
                  </PlayerContainer>
                  </div>
                ))
              )}
          </div>
        </div>
      </section>

      <AboutUs />
    </Layout>
  )
}

export default PageTemplate
