/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import blueChevronRight from 'img/blue-chevron-right.svg'

// Helpers
import { getLanguage } from 'services/language'

// Components
import CustomLink from 'components/shared/CustomLink'

// Third Party
import styled from 'styled-components'

const StyledVacancy = styled.div`
  background-color: ${props => props.theme.color.lightGrey};
  position: relative;

  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 2000px;
    right: -2000px;
    top: 0;
    background-color: ${props => props.theme.color.lightGrey};
  }
`

const StyledCustomLink = styled(CustomLink)`
  &:hover {
    p {
      color: ${props => props.theme.color.text.contrast};
    }
  }
`

const Vacancies = () => {
  const language = getLanguage()
  const { vacancyNL, vacancyEN } = useStaticQuery(graphql`
    {
      vacancyNL: allWpVacature(filter: {locale: {locale: {eq: "nl_NL"}}}, sort: {order: DESC, fields: date}) {
        edges {
          node {
            databaseId
            title
            uri
          }
        }
      }

      vacancyEN: allWpVacature(filter: {locale: {locale: {eq: "en_US"}}}, sort: {order: DESC, fields: date}) {
        edges {
          node {
            databaseId
            title
            uri
          }
        }
      }
    }
  `)

  const vacancyPost = language === 'nl_NL' ? vacancyNL.edges : vacancyEN.edges

  return (
    <>
      {vacancyPost.map(({ node: { wpId, title, uri } }) => (
        <StyledVacancy key={wpId} className="col-12 py-3 my-3">
          <StyledCustomLink className="d-flex" to={uri}>
            <img className="mr-2" src={blueChevronRight} alt="" />
            <p className="mb-0 text-uppercase color-text-main">{title}</p>
          </StyledCustomLink>
        </StyledVacancy>
      ))}
    </>
  )

}

export default Vacancies